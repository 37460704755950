/* Binding event for the movement of the mouse.*/
document.onmousemove = mouseMove;
/* Binding event for when a mouse button is released. */
document.onmouseup = mouseUp;
/* The root path for the actions. */
var actionPath = "/action/";
/* This variable is always true on any ajax call unless the voidAjax method
 is called to attempt to cancel the ajax call, even though it is not
 a guarantee. */
var ajaxAllowed = true;
/* Contains the currently opened windows. */
var openedWindows = {};
/* Contains the currently opened menus. */
var openedMenus = {};
/* The currently selected group. */
var selectedGroup = null;
/* The Z index (on the Z plane) for the last selected absolute DOM Object. */
var zIndex = 100;
/* The current page being swaped. (null otherwise). */
var swapPage;
/* The current section being swaped. (null otherwise). */
var swapSection;


$(window).on("load", function () {
    $(".mvb_window").on("click", function () {
        setFocus(this);
    });
    $(".master_icon").on("click", function () {
        $(this).parent().toggleClass("masterMessage_opened");
    });
});

function customURLConverter(url, node, on_save, name){
    var checkURL;
    var urlPrefix;

    // Get the first 7 characters of the string
    checkURL = url.substring(0, 7);

    // Determine if those characters are coming from the image uploader which uses /system URL
    if (checkURL === "/static")
    {
        // prefix the incoming URL with my domain
        url = url;
    }
    return url;
}

/* Function which makes a given item draggable. There is also an additional
 optional parameter that allows to set a clickable source to drag the
 original item. This can be useful if you don't want the entire item to
 be clickable, but rather have a selected region clickable while still 
 being able to drag the entire item. */
function makeDraggable(item, dragSource) {
    if (!item) {
        return;
    }
    setFocus(item);
    if (dragSource) {
        dragSource.onmousedown = function (ev) {
            dragObject = item;
            mouseOffset = getMouseOffset(this, ev);
            return false;
        };
        dragSource.ontouchstart = function (ev) {
            ev.preventDefault();
            setFocus(item);
            dragObject = item;
            mouseOffset = getTouchOffset(this, ev);
            return false;
        };
        dragSource.ontouchmove = touchMove;
        dragSource.ontouchend = touchEnd;
        dragSource.ontouchcancel = touchEnd;
    } else {
        item.onmousedown = function (ev) {
            setFocus(item);
            mouseOffset = getMouseOffset(this, ev);
            return false;
        };
    }
}
function getTouchOffset(target, ev) {
    ev = ev || window.event;
    var oev = ev.originalEvent || ev;
    var docPos = getPosition(target);
    var mousePos = mouseCoords(oev.changedTouches[0]);
    return {x: mousePos.x - docPos.x, y: mousePos.y - docPos.y};
}
function touchMove(ev) {
    ev = ev || window.event;
    ev.preventDefault();
    var oev = ev.originalEvent || ev;
    var touchPos = mouseCoords(oev.changedTouches[0]);
    mouseCoordsXY = touchPos;
    if (dragObject) {
        dragObject.style.position = 'absolute';
        dragObject.style.top = touchPos.y - mouseOffset.y + "px";
        dragObject.style.left = touchPos.x - mouseOffset.x + "px";
        return false;
    }
}
function touchEnd() {
    dragObject = null;
}

/* Opens a window based on its given id. The blackout is a boolean optional
 parameter which will put a black out behind the window if set to true. */
function openWindow(id, blackout, blackoutcloseable) {
    if (disableApp && id != 'errorMsg' && id != 'siteErrorMsg') {
        popErrorSite('error.message.ie6');
        return;
    }
    if (blackout) {
        openedWindows[id] = "blackout";
        var blackoutDiv = document.getElementById('blackout');
        blackoutDiv.style.display = "block";
        setFocus(blackoutDiv);
        if (blackoutcloseable) {
            blackoutDiv.onclick = function () {
                closeWindow(id);
            }
        }
    } else {
        openedWindows[id] = id;
    }
    closeAllMenu();
    var tbar = document.getElementById(id + '_titlebar');
    if (tbar) {
        //We have a draggable window, make it so!
        makeDraggable(document.getElementById(id), tbar.getElementsByClassName('windowTitle')[0]);
    }
    var elem = document.getElementById(id);
    elem.style.display = "block";
    setFocus(elem);
    centerElement(id);
    if (id == "managerImageWindow") {
        loadImagePreviews();
    } else if (id == "logsWindow") {
        document.getElementById('logsWindowIframe').src = 'logs-window';
    }
}

function openMenuWindow(sel, id, blackout, blackoutcloseable) {
    if (disableApp && id != 'errorMsg' && id != 'siteErrorMsg') {
        popErrorSite('error.message.ie6');
        return;
    }
    closeOtherWindows(id);
    if (blackout) {
        openedWindows[id] = "blackout";
        var blackoutDiv = document.getElementById('blackout');
        blackoutDiv.style.display = "block";
        setFocus(blackoutDiv);
        if (blackoutcloseable) {
            blackoutDiv.onclick = function () {
                closeWindow(id);
            };
        }
    } else {
        if ($(sel).hasClass('mg2_item_opened')) {
            openedWindows[id] = "closed";
            $(sel).removeClass('mg2_item_opened');
            $(sel).closest('.topMenuBar').removeClass('menuWindowOpened');
        } else {
            openedWindows[id] = id;
            document.getElementById(id).style.height = "100%";
            $(sel).addClass('mg2_item_opened');
            $(sel).closest('.topMenuBar').addClass('menuWindowOpened');
        }
    }
    var elem = document.getElementById(id);
    setFocus(elem, "505");
    slideElement(id);
    if (id == "managerImageWindow") {
        loadImagePreviews();
    } else if (id == "logsWindow") {
        document.getElementById('logsWindowIframe').src = 'logs-window';
    }

    var ajaxFrame = elem.querySelector(".mediaListAjax");
    if (ajaxFrame && !$(ajaxFrame).hasClass("loaded")) {
        ajaxFrame.src = ajaxFrame.getAttribute("data-src");
    }
}

/* This function closes all of the opened menus except the current one. */
function closeOtherMenus(id) {
    for (var i in openedMenus) {
        if (openedMenus[i] && openedMenus[i] != "closed" && openedMenus[i] != id) {
            slideElement(openedMenus[i]);
            openedMenus[i] = "closed";
        }
    }
}
/* This function closes all of the opened menus except the current one. */
function closeOtherWindows(id) {
    for (var i in openedWindows) {
        if (openedWindows[i] && openedWindows[i] != "closed" && openedWindows[i] != id && !$('#' + openedWindows[i]).hasClass('mvb_window')) {
            $('.mg2_item_opened').removeClass('mg2_item_opened');
            slideElement(openedWindows[i]);
            openedWindows[i] = "closed";
        }
    }
}

/* Function which highlights or un-highlights a given menu item. */
function highlightMenu(menuItem, id, on) {
    if (on) {
        if (!openedMenus[id]) {
            menuItem.className = 'topMenuItem';
        }
    } else {
        menuItem.className = 'topMenuItem topMenuItemH';
    }
}
/* Centers the DOM Object with the given id. The centering is based on the 
 current view pane's size. */
function centerElement(elementId) {
    var div = document.getElementById(elementId);
    var w = div.offsetWidth;
    var h = div.offsetHeight;
    var dimensions = getViewPaneSize();
    var scrollOffset = getScrollXY();
    $(div).css('position', 'absolute');
    var offsetTop = parseInt((((dimensions[1] - h) / 2) + scrollOffset[1]));
    if (offsetTop < 50) {
        offsetTop = 50;
    }

    div.style.left = ((((dimensions[0] - w) / 2) + scrollOffset[0]) + "px");
    div.style.top = offsetTop + "px";
    setFocus(div);
}

/* Slides the DOM Object with the given id. The centering is based on the 
 current view pane's size. */
function slideElement(elementId) {
    var div = document.getElementById(elementId);
    var dimensions = getMenuPaneSize();
    var divWidth = document.documentElement.clientWidth - dimensions[0];
    var $iframe = $(div).find("iframe");
    // Prevents blinking of Iframe when closing panel
    if ($iframe.length != 0 && openedWindows[elementId] == 'closed' && !$iframe.hasClass("loaded")) {
        $iframe.css('display', 'none');
    }
    if (!div.style.maxWidth || div.style.maxWidth === "") {
        div.style.maxWidth = div.style.width;
    }
    div.style.width = divWidth + "px";
    div.style.left = dimensions[0] + "px";
    $(div).toggle("slide", {direction: "left"}, 500, function() {
        // Prevents blinking of Iframe when opening panel
        if ($iframe.length != 0 && openedWindows[elementId] != 'closed') {
            $iframe.css('display', 'block');
        }
    });
}

/* Returns the x,y coordinates of the scrolling in the page's view pane. */
function getScrollXY() {
    var scrOfX = 0, scrOfY = 0;
    if (typeof (window.pageYOffset) == 'number') {
        //Netscape compliant
        scrOfY = window.pageYOffset;
        scrOfX = window.pageXOffset;
    } else if (document.body && (document.body.scrollLeft
            || document.body.scrollTop)) {
        //DOM compliant
        scrOfY = document.body.scrollTop;
        scrOfX = document.body.scrollLeft;
    } else if (document.documentElement && (document.documentElement.scrollLeft
            || document.documentElement.scrollTop)) {
        //IE6 standards compliant mode
        scrOfY = document.documentElement.scrollTop;
        scrOfX = document.documentElement.scrollLeft;
    }
    return [scrOfX, scrOfY];
}

/* Returns the size (width, height) of the browser's view pane. */
function getViewPaneSize() {
    var myWidth = 0, myHeight = 0;
    if (typeof (window.innerWidth) == 'number') {
        //Non-IE
        myWidth = window.innerWidth;
        myHeight = window.innerHeight;
    } else if (document.documentElement && (document.documentElement.clientWidth
            || document.documentElement.clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = document.documentElement.clientWidth;
        myHeight = document.documentElement.clientHeight;
    } else if (document.body && (document.body.clientWidth
            || document.body.clientHeight)) {
        //IE 4 compatible
        myWidth = document.body.clientWidth;
        myHeight = document.body.clientHeight;
    }
    return [myWidth, myHeight];
}

/* Returns the size (width, height) of the menu's view pane. */
function getMenuPaneSize() {
    var myWidth = 0, myHeight = 0;
    if (typeof (document.getElementById('topMenuBar').innerWidth) == 'number') {
        //Non-IE
        myWidth = document.getElementById('topMenuBar').innerWidth;
        myHeight = document.getElementById('topMenuBar').innerHeight;
    } else if (document.documentElement && (document.getElementById('topMenuBar').clientWidth
            || document.getElementById('topMenuBar').clientHeight)) {
        //IE 6+ in 'standards compliant mode'
        myWidth = document.getElementById('topMenuBar').clientWidth;
        myHeight = document.getElementById('topMenuBar').clientHeight;
    }
    return [myWidth, myHeight];
}

/* This function closes all of the opened menus. */
function closeAllMenu() {
    for (var i in openedMenus) {
        if (openedMenus[i]) {
            openedMenus[i] = false;
            document.getElementById(i).style.display = "none";
            document.getElementById('parent_' + i).className = 'topMenuItem';
        }
    }
}
/* Closes the current text window. */
function closeTextWindow() {
    closeWindow('textWindow' + currentTextId);
    currentTextId = null;
}

/* Closes a window based on its given id.. */
function closeWindow(id) {
    openedWindows[id] = "closed";
    var removeBlackout = true;
    for (var windowId in openedWindows) {
        if (openedWindows[windowId] != "closed") {
            setFocus(document.getElementById(windowId));
        }
        if (openedWindows[windowId] == "blackout") {
            removeBlackout = false;
        }
    }
    if (id == "imageWindow") { //Special case which forces the page to reload.
        window.location.reload(false);
    } else {
        if (removeBlackout) {
            document.getElementById('blackout').style.display = "none";
        }
        document.getElementById(id).style.display = "none";
        $('.topMenuBar').removeClass('menuWindowOpened');
        $('.mg2_item_opened').removeClass('mg2_item_opened');
    }
}

function closeMenuWindow(id) {
    var removeBlackout = true;
    for (var windowId in openedWindows) {
        if (openedWindows[windowId] != "closed") {
            setFocus(document.getElementById(windowId));
        }
        if (openedWindows[windowId] == "blackout") {
            removeBlackout = false;
        }
    }
    if (id == "imageWindow") { //Special case which forces the page to reload.
        window.location.reload(false);
    } else {
        if (!removeBlackout || $('#' + id).hasClass('mvb_window')) {
            document.getElementById('blackout').style.display = "none";
            document.getElementById(id).style.display = "none";
        } else {
            $('.topMenuBar').removeClass('menuWindowOpened');
            $('.mg2_item_opened').removeClass('mg2_item_opened');
            slideElement(id);
        }
    }
    openedWindows[id] = "closed";
}
/* This method handles all of the ajax requests of the front-end part of the
 FoxOne application. The two parameters are the url of the request and the
 method to call upon the callback received. This method is compliant with
 all mainstream browsers but may require to be updated should a new 
 browser be released. 
 
 This method also handles the various types of responses from the client.
 For example, if an error is sent, the error window will be popped, if a 
 void type is sent, then no callback method will be called and if a string
 or a list is returned, it will be appropriatly be passed to the given
 function. */
function ajax(url, method, noload, returnVar, usePost, reqHeader) {
    ajaxAllowed = true;
    var xmlHttp;
    try {
        xmlHttp = new XMLHttpRequest();
    } catch (e) {
        try {
            xmlHttp = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
                return false;
            }
        }
    }
    if (pageLoaded && !noload) {
        ajaxBlackout(true);
    }
    xmlHttp.onreadystatechange = function () {
        try {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                if (!ajaxAllowed) {
                    //Ajax call has been voided, cancel the response.
                    return;
                }
                var response = xmlHttp.responseText;
                if (response.indexOf("void~!~") != -1) {
                    //Do nothing, we have a void.
                } else if (response.indexOf("error-") != -1) {
                    if (admlog) {
                        popError('errorMessageAjax');
                        document.getElementById('errorMessageAjax').innerHTML = response.substring(6);
                    } else {
                        popErrorSite('errorMessageAjaxSite');
                        document.getElementById('errorMessageAjaxSite').innerHTML = response.substring(6);
                    }
                } else if (response.indexOf("%$") != -1) {
                    method(response.split("%$"), returnVar);
                } else {
                    method(response, returnVar);
                }
                if (pageLoaded && !noload) {
                    ajaxBlackout(false);
                }
            }
        } catch (e) {
            if (pageLoaded && !noload) {
                ajaxBlackout(false);
            }
        }
    };
    var noCache = "cache=" + Math.random();
    var nonCacheUrl = ((url.indexOf("?") != -1) ? url + "&" : url + "?");
    if (usePost) {
        xmlHttp.open("POST", (nonCacheUrl + noCache), true);
        if (!reqHeader) {
            xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        } else {
            for (var k in reqHeader) {
                xmlHttp.setRequestHeader(k, reqHeader[k]);
            }
        }
        xmlHttp.send();
    } else {
        xmlHttp.open("GET", (nonCacheUrl + noCache), true);
        xmlHttp.send(null);
    }
}

function ajaxCache(url, method, noload, returnVar, usePost, reqHeader) {
    ajaxAllowed = true;
    var xmlHttp;
    try {
        xmlHttp = new XMLHttpRequest();
    } catch (e) {
        try {
            xmlHttp = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                xmlHttp = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {
                return false;
            }
        }
    }
    if (pageLoaded && !noload) {
        ajaxBlackout(true);
    }
    if (usePost) {
        xmlHttp.open("POST", url, true);
        if (!reqHeader) {
            xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        } else {
            for (var k in reqHeader) {
                xmlHttp.setRequestHeader(k, reqHeader[k]);
            }
        }
    } else {
        xmlHttp.open("GET", url, true);
        xmlHttp.send(null);
    }
    xmlHttp.onreadystatechange = function () {
        try {
            if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                if (!ajaxAllowed) {
                    //Ajax call has been voided, cancel the response.
                    return;
                }
                var response = xmlHttp.responseText;
                if (response.indexOf("void~!~") != -1) {
                    //Do nothing, we have a void.
                } else if (response.indexOf("error-") != -1) {
                    if (admlog) {
                        popError('errorMessageAjax');
                        document.getElementById('errorMessageAjax').innerHTML = response.substring(6);
                    } else {
                        popErrorSite('errorMessageAjaxSite');
                        document.getElementById('errorMessageAjaxSite').innerHTML = response.substring(6);
                    }
                } else if (response.indexOf("%$") != -1) {
                    method(response.split("%$"), returnVar);
                } else {
                    method(response, returnVar);
                }
                if (pageLoaded && !noload) {
                    ajaxBlackout(false);
                }
            }
        } catch (e) {
            if (pageLoaded && !noload) {
                ajaxBlackout(false);
            }
        }
    };
}

function ajaxSubmitE(formId) {
    ajaxSubmit(document.getElementById(formId));
}
function ajaxSubmit(form) {
    var inputs = form.getElementsByTagName("input");
    var isAjax = false;
    var str = "?";
    var first = true;
    for (var i in inputs) {
        var name = inputs[i].name;
        var value = inputs[i].value;
        if (name == "ajax" && value == "true") {
            isAjax = true;
        }
        if (!first) {
            str += "&";
        } else {
            first = false;
        }
        str += name + "=" + value;
    }
    if (isAjax) {
        ajax(form.action + str, openStructureTree);
        try {
            swapPage = null;
            swapSection = null;
            selectedBoundPageId = null;
//            closeWindow('swapSection');
//            closeWindow('swapPage');
//            closeWindow('movePage');
            closeWindow('makeMainPage');
            closeWindow('makeFloatPage');
            closeWindow('lockPage');
            closeWindow('unlockPage');
            closeWindow('boundPage');
        } catch (exception) {
        }
        return false;
    } else {
        form.submit();
    }
}
function voidAjax() {
    ajaxAllowed = false;
    ajaxBlackout(false);
}

/* This method locks anything from being clicked in the window and displays 
 an ajax loading bar. If the on parameter is set to false, this blackout 
 is removed. */
function ajaxBlackout(on, load) {
    if (load) {
        pageLoaded = load;
    }
    if (!on) {
        var div = document.getElementById('ajaxBlackout');
        if (div) {
            div.style.display = "none";
        }
        ajaxLoader(on);
        return;
    } else {
        alignBlackout();
    }
}

function alignBlackout(on) {
    try {
        var blackoutDiv = document.getElementById('ajaxBlackout');
        var view = getViewPaneSize();
        if (navigator.userAgent.indexOf("Firefox") != -1) {
            blackoutDiv.style.display = "block";
            if (document.height < view[1]) {
                blackoutDiv.style.height = "100%";
            } else {
                blackoutDiv.style.height = (document.height) + 'px';
            }
            blackoutDiv.style.width = document.width + 'px';
            setFocus(blackoutDiv);
//          centerElement('ajaxLoaderImg');
        } else {
            blackoutDiv.style.display = "block";
            if (document.body.scrollHeight + 110 < view[1]) {
                blackoutDiv.style.height = "100%";
            } else {
                blackoutDiv.style.height = (document.body.scrollHeight) + 'px';
            }
            blackoutDiv.style.width = document.body.scrollWidth + 'px';
            setFocus(blackoutDiv);
//          centerElement('ajaxLoaderImg');
        }
    } catch (exception) {
        //We put a try/catch in case the call to the blackout is done before
        //the page is done loading.
    }
}

function ajaxLoader(on, adminLoad) {
    var el = document.getElementById("ajaxLoaderBar4");
    if (el && (!adminLoad || on && adminLoad && el.hasAttribute("data-on"))) {
        el.style.display = on ? "block" : "none";
    }
}

function addCookie(key, value) {
    var date = new Date();
    date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
    var expires = "; expires=" + date.toGMTString();
    document.cookie = key + "=" + value + "; path=/" + expires;
}


/* Does the mouse over effect of components. This method highlights the 
 border based on the 'hover' boolean and will display the delete button
 or hide it if the component is typed. */
function componentMouseOver(id, isTyped, hover) {
    var componentDiv = document.getElementById('componentDiv_' + id);
    var bulkdiv = document.getElementById('mg2BulkImage' + id);
    if (hover) {
        if (isTyped) {
            //Display the delete/swap buttons.
            document.getElementById('componentDivDel_' + id)
                    .style.display = "block";
            document.getElementById('componentDivSwapUp_' + id)
                    .style.display = "block";
            document.getElementById('componentDivSwapDown_' + id)
                    .style.display = "block";
            if (document.getElementById('componentDivHide_' + id) !== null) {
                document.getElementById('componentDivHide_' + id)
                        .style.display = "block";
            }
            if (bulkdiv && navigator.appVersion.indexOf("MSIE") == -1) {
                bulkdiv.style.display = "block";
            }
        }
        componentDiv.style.border = "1px dashed red";
    } else {
        if (isTyped) {
            //Hide the delete/swap buttons.
            document.getElementById('componentDivDel_' + id)
                    .style.display = "none";
            document.getElementById('componentDivSwapUp_' + id)
                    .style.display = "none";
            document.getElementById('componentDivSwapDown_' + id)
                    .style.display = "none";
            if (document.getElementById('componentDivHide_' + id) !== null) {
                document.getElementById('componentDivHide_' + id)
                        .style.display = "none";
            }
            if (bulkdiv) {
                bulkdiv.style.display = "none";
            }
        }
        componentDiv.style.border = "1px dashed black";
    }
}

function deleteComponentMaster() {
    var name = document.getElementById('compMasterNameInput').value;
    if (confirm('Are you sure that you wish to delete the component "' +
            name + '"?')) {
        window.location = '/action/masterComponentCreate?delete&name=' + name;
    }
}
/* This function pops up the window to select a component and filters the
 choices by only displaying those in the given allowed list. This method
 will also "select" the first component in the allowed list to provide
 the user with a default value and prevent errors for empty value submits. */
function popComponentSelect(id, allowed, name) {
    var allowedList = allowed.split(",");
    var selectDiv = document.getElementById('componentPreviews-select');
    document.getElementById('nameSelectComponent').value = allowedList[0];
    document.getElementById('compPreview_' + allowedList[0] + "_firstChild").className = "selected";
    var html = "";
    for (var i in allowedList) {
        var n = allowedList[i];
        if (i != 0 && document.getElementById('compPreview_' + n + "_firstChild")) {
            document.getElementById('compPreview_' + n + "_firstChild").className = "";
        }
        html = html + document.getElementById('compPreview_' + n).innerHTML;
    }
    selectDiv.innerHTML = html;
    document.getElementById('componentIdInput').value = id;
    document.getElementById('componentNameInput').value = name;
    openWindow('componentSelect', true);
}

/* Submits the form for the current text being edited. */
function submitText(id) {
    if (id) {
        var pName = document.getElementById('textFormPageName' + id).value;
        var l = document.getElementById('textFormLang' + id).value;
        var newText = tinyMCE.get('text' + id).getContent();
        ajaxBlackout(true);
        $.ajax({
            type: 'POST',
            url: '/action/updateText',
            data: {ajax: true, textId: id, lang: l, pageName: pName, text: newText},
            success: function(response, status, xhr) {
                submitTextComplete(id, response, status);
            },
            error: submitTextError
        });
    } else {
        document.getElementById('formSaveText' + currentTextId).submit();
    }
}
function submitTextComplete(textId, response, status) {
    if (response.indexOf("error") > -1) {
        return submitTextError(null, status, response);
    }
    ajaxBlackout(false);
    document.getElementById('displayTextInnerWindow' + textId).style.textAlign = 'left';
    document.getElementById('displayTextInnerWindow' + textId).innerHTML =
            tinyMCE.get('text' + textId).getContent();
    closeWindow('textWindow' + textId);
}
function submitTextError(xhr, status, err) {
    ajaxBlackout(false);
    console.log(status, err);
}

/* @Deprecated 
function selectComponent(type) {
    var nodes = document.getElementById('componentPreviews-select').childNodes;
    for (var i in nodes) {
        var node = nodes[i];
        if (node.nodeType == 1) {
            node.style.borderColor = "white";
        }
    }
    document.getElementById('nameSelectComponent').value = type;
    document.getElementById('compPreview_' +
            type + "_firstChild").style.borderColor = "red";
}*/
function selectComponent(type) {
    var elems = document.getElementById('componentPreviews-select').getElementsByClassName('selected');
    for (var i = 0; i < elems.length; i++) {
        elems[i].className = '';
    }
    document.getElementById('nameSelectComponent').value = type;
    document.getElementById('compPreview_' + type + '_firstChild').className = 'selected';
}

/* Opens the delete component window and sets a hidden value. */
function popDeleteComponent(id, name) {
    openWindow('deleteComponent', true);
    document.getElementById('componentIdDeleteInput').value = id;
    document.getElementById('componentNameDeleteInput').value = name;
}


var componentSwapFirstDiv = null;
var componentSwapSecondDiv = null;
var componentSwapPos = null;
var componentSwapNextPos = null;
var componentSwapFirstClass = null;
var componentSwapSecondClass = null;
function swapComponents(name, id, up, pageId, max) {
    var index = id.indexOf('-Component') + 10;
    componentSwapPos = id.substring(index);
    componentSwapNextPos =
            (up ? parseInt(componentSwapPos) - 1 : parseInt(componentSwapPos) + 1);
    var firstId = 'componentDiv_' + name + '-Component' + componentSwapPos;
    var secondId = 'componentDiv_' + name + '-Component' + (componentSwapNextPos);
    if ((componentSwapPos == 1 && up) || (componentSwapPos == max && !up)) {
        componentSwapPos = null;
        componentSwapNextPos = null;
        return;
    }
    try {
        componentSwapFirstDiv = document.getElementById(firstId);
        componentSwapSecondDiv = document.getElementById(secondId);
        componentSwapFirstClass = componentSwapFirstDiv.className;
        componentSwapSecondClass = componentSwapSecondDiv.className;
        componentMouseOver(id, true, false);
        componentMouseOver(id.split('Component' + componentSwapPos)
                .join('Component' + componentSwapNextPos), true, false);
        ajax(actionPath + "swapComponent?pageId=" + pageId + "&componentId=" + id + "&name="
                + name + "&up=" + up, swapComponentSuccess());
    } catch (exception) {
        //Trying to move beyond the scope.
    }
}

function swapComponentSuccess() {
    var temp = componentSwapFirstDiv.innerHTML
            .split('Component' + componentSwapPos).join('Component' + componentSwapNextPos);
    componentSwapFirstDiv.innerHTML = componentSwapSecondDiv.innerHTML
            .split('Component' + componentSwapNextPos).join('Component' + componentSwapPos);
    componentSwapSecondDiv.innerHTML = temp;
    componentSwapFirstDiv.className = componentSwapSecondClass;
    componentSwapSecondDiv.className = componentSwapFirstClass;
    componentSwapFirstDiv = null;
    componentSwapSecondDiv = null;
    componentSwapPos = null;
    componentSwapNextPos = null;
    componentSwapFirstClass = null;
    componentSwapSecondClass = null;
}

function hideComponents(name, id, pageId) {
    var btn = $('#componentDivHide_' + id);
    var hide = !btn.hasClass("hidden-comp");
    ajax(actionPath + "hideComponent?pageId=" + pageId + "&componentId=" + id + "&name="
            + name + "&hide=" + hide, hideComponentSuccess(btn));    
}

function hideComponentSuccess(btn) {
    btn.toggleClass("hidden-comp");
}

/* Function which pops the error window and sets the proper error message
 to be displayed based on the given id. */
function popError(key) {
    if (key.indexOf('errorMessageAjax') != -1) {
        document.getElementById('errorMessageAjax').style.display = "block";
        document.getElementById('errorMessageDivOriginal').style.display = "none";
    } else {
        document.getElementById('errorMessageAjax').style.display = "none";
        document.getElementById('errorMessageDivOriginal').style.display = "block";
        ajax('/action/getLabel?key=' + key, popErrorComplete);
    }
}
function popErrorComplete(response) {
    document.getElementById('errorMessageDivOriginal').innerHTML = response;
    openWindow('errorMsg', true);
}
function popErrorSite(key) {
    if (key.indexOf('errorMessageAjaxSite') != -1) {
        document.getElementById('errorMessageAjaxSite').style.display = "block";
        document.getElementById('errorMessageSite').style.display = "none";
        openWindow('siteErrorMsg', true);
    } else {
        document.getElementById('errorMessageAjaxSite').style.display = "none";
        document.getElementById('errorMessageSite').style.display = "block";
        ajax('/action/getLabel?key=' + key, popErrorSiteComplete);
    }
}
function popErrorSiteComplete(response) {
    document.getElementById('errorMessageSite').innerHTML = response;
    openWindow('siteErrorMsg', true);
}



/* This function submits the form associated with the given id. If there are
 inputs of type text that are empty, this function will call the error
 window to be popped up to advise the user to fill all the inputs. */
function submitForm(id) {
    var form = document.getElementById(id);
    var inputs = form.getElementsByTagName("input");
    var validForm = true;
    for (i in inputs) {
        if (inputs[i].type == "text" && inputs[i].value === "" && inputs[i].className.indexOf("skip") === -1) {
            validForm = false;
            $(inputs[i]).addClass("error");
            $(inputs[i]).on("mouseup", function() {
                $(this).removeClass("error");
            });
            $('html, body').animate({
                scrollTop: $(inputs[i]).offset().top
            });
            break;
        }
        if (inputs[i].type == "text" && inputs[i].name.startsWith("tag") && inputs[i].value.startsWith("/")) {
            popError("error.message.url.slash");
            return;
        }
        if (inputs[i].type == "text" && inputs[i].name.startsWith("tag") && /^\d+$/.test(inputs[i].value)) {
            popError("error.message.url.numbers") ;
            return;
        }
    }

    if (!validForm) {
        if (document.getElementById('errorMessageAjax') != null) {
            popError('error.message.emptyForm');
        }
    } else {
        form.submit();
    }
}



/* This method takes the hidden preloaded list of all the users and  restores
 it when a new group is selected so that previous modifications are ignored.
 Afterwards, this method makes an ajax call to load the list of users 
 associated to the given group. */
function accessSelectGroup(sel, groupId) {
    $('.access_group_selected').removeClass('access_group_selected');
    $(sel).addClass('access_group_selected');
    document.getElementById('accessDropZone').innerHTML = "";
    document.getElementById('userAccessFrame').innerHTML =
            document.getElementById('-BAKuserAccessFrame')
            .innerHTML.split("-BAK").join("");
    selectedGroup = groupId;
    ajax(actionPath + "getGroupUsers?groupId=" + groupId,
            accessSelectGroupResponse, true);
}

/* Ajax callback which is done when the back-end responds with a list of
 users for the selected group. This function properly displays them. */
function accessSelectGroupResponse(list) {
    if (list == "void") {
        return;
    }
    var container = document.getElementById('accessDropZone');
    for (var item in list) {
        var arr = list[item].split(',');
        var str = document.getElementById('userAcessFrame_members').firstElementChild.cloneNode(true);
        if (item != list.length - 1) {
            $(str).attr('id', 'AJAXLOAD-userItem_' + arr[0]);
            $(str).find("a[data-user='link']").attr('href', "javascript:accessSwapUp(\'" + arr[0] + "\')");
            $(str).find("a[data-user='link']").prepend(arr[1]);
            container.innerHTML = container.innerHTML + "" + str.outerHTML;
        }
    }
}

/* Makes the ajax call to add a user to a group based on the currently 
 selected group and the given user id. If there are no selected group an
 error will be popped. */
function addUserGroup(userId) {
    if (selectedGroup != null) {
        ajax(actionPath + "addGroupUser?userId=" + userId + "&groupId="
                + selectedGroup, accessCompleteSwapDown, true);
    } else {
        popError('error.message.noselectedgroup');
    }
}
/* Allows for a user to be swapped down into the group user div. Errors and
 other related issues are handled in the addUserGroup() function. */
function accessSwapDown(id) {
    swapDownId = id;
    addUserGroup(id);
}

/* Allows for a user to be swapped up from the group user relation div, in
 other words, a request is made to delete this group user relation. */
function accessSwapUp(id) {
    swapUpId = id;
    openWindow('accessGroupUserDelete', true);
}

/* Ajax callback made when the swapping of a user is completed on the back-end
 which allows a user to now be associated with the currently selected group.
 Therefore, we properly display the user in the group user div. */
function accessCompleteSwapDown(response) {
    var item = document.getElementById('userItem_' + swapDownId);
    var dropZone = document.getElementById('accessDropZone');
    dropZone.appendChild(item);
    var a = document.getElementById('a_user_' + swapDownId);
    a.href = "javascript:accessSwapUp('" + swapDownId + "');";
    $('#a_user_' + swapDownId).find('.mg2_add_to_group').addClass('mg2_remove_from_group');
    $('#a_user_' + swapDownId).find('.mg2_add_to_group').removeClass('mg2_add_to_group');
    openWindow('accessGroupUserConfirm');
}

/* Ajax callback made to confirm that the user has been removed from its 
 relation with the currently selected group by the back-end, therefore
 we remove it from the group user div. */
function accessCompleteSwapUp(response) {
    var id = 'AJAXLOAD-userItem_' + swapUpId;
    try {
        //Try to see if it was ajax loaded and remove it.
        document.getElementById('accessDropZone').removeChild(
                document.getElementById(id));
    } catch (e) {
        //If not, it was added by the user, so swap it back up.
        id = 'userItem_' + swapUpId;
        var item = document.getElementById(id);
        var dropZone = document.getElementById('userAccessFrame');
        dropZone.appendChild(item);
        var a = document.getElementById('a_user_' + swapUpId);
        a.href = "javascript:accessSwapDown('" + swapUpId + "');";
    }

    $('#' + id).find('.mg2_remove_from_group').addClass('mg2_add_to_group');
    $('#' + id).find('.mg2_remove_from_group').removeClass('mg2_remove_from_group');
    closeWindow('accessGroupUserDelete');
}


var currentUserPageId = null;

/* Fetches the users authorized to access a page (accessible throught the structure tree)*/
function accessPerPage(pageId) {
    currentUserPageId = pageId;
    document.getElementById('acessUserPageTitle').innerHTML =
            document.getElementById('pageName_' + pageId).innerHTML;
    ajax(actionPath + "getUserPage?pageId=" + pageId, accessPerPageComplete);
}

/* Displays the list of persons that are authorized to modify a page's content (accessible throught the structure tree)*/
function accessPerPageComplete(list) {
    openWindow('accessUserPage', true);
    $('#userPerPageList .accessItem').each(function () {
        $(this).attr('id', $(this).attr('id').replace('invisible_', ''));
        $(this).find('a').attr('id', $(this).find('a').attr('id').replace('invisible_', ''));
        $(this).css('display', '');
    });
    if (list[0].length == 1) {
        //Empty list.
        document.getElementById('userPerPageDropList').innerHTML = "";
        return;
    }
    var container = document.getElementById('userPerPageDropList');
    container.innerHTML = "";
    for (var item in list) {
        var arr = list[item].split(',');
        var str = document.getElementById('pageAcessFrame_members').firstElementChild.cloneNode(true);
        if (item != list.length - 1) {
            if ($('#userPerPageList #userPage_' + arr[0]).length != 0) {
                $('#userPerPageList #userPage_' + arr[0]).css("display", "none");
                $('#userPerPageList #userPage_' + arr[0]).find("a").attr('id', "userPageLink_invisible_" + arr[0]);
                $('#userPerPageList #userPage_' + arr[0]).attr("id", "userPage_invisible_" + arr[0]);
            }
            $(str).attr('id', 'userPage_' + arr[0]);
            $(str).find("a[data-user='link']").attr('id', "userPageLink_" + arr[0]);
            $(str).find("a[data-user='link']").attr('href', "javascript:accessDeleteUserPage(\'" + arr[0] + "\')");
            $(str).find("a[data-user='link'] .mg2_remove_from_group").before(arr[1]);
            container.innerHTML = container.innerHTML + "" + str.outerHTML;
        }
    }
}

/* Adds a user to the list of authorized persons to modify a page's content */
var accessUserPageUserId = null;
function accessAddUserPage(userId) {
    accessUserPageUserId = userId;
    ajax(actionPath + "addUserPage?userId=" + userId + "&pageId=" + currentUserPageId,
            accessAddUserPageComplete, true);
}

/* Display the user to the right list*/
function accessAddUserPageComplete(response, listToDrop) {
    if (listToDrop == null) {
        listToDrop = 'userPerPageDropList';
    }
    var div = $("#" + listToDrop + " #userPage_" + accessUserPageUserId).length;
    if (div != 0 && listTopDrop == 'userPerPageDropList') {
        //Do nothing, it's already there.
    } else {

        var item = document.getElementById('userPage_' + accessUserPageUserId);
        var dropZone = document.getElementById(listToDrop);
        var a = document.getElementById('userPageLink_' + accessUserPageUserId);
        if (listToDrop == 'userPerPageDropList') {
            dropZone.appendChild(item.cloneNode(true));

            $('#userPerPageList #userPageLink_' + accessUserPageUserId).attr('id', "userPageLink_invisible_" + accessUserPageUserId);
            $('#userPerPageList #userPage_' + accessUserPageUserId).attr("id", "userPage_invisible_" + accessUserPageUserId);
            $('#userPerPageList #userPage_invisible_' + accessUserPageUserId).css("display", "none");

            $('#userPageLink_' + accessUserPageUserId).attr('href', "javascript:accessDeleteUserPage('" + accessUserPageUserId + "')");
            $('#userPageLink_' + accessUserPageUserId).find('.mg2_add_to_group').addClass('mg2_remove_from_group');
            $('#userPageLink_' + accessUserPageUserId).find('.mg2_add_to_group').removeClass('mg2_add_to_group');
        } else {
            $(item).remove();
            $('#userPerPageList #userPageLink_invisible_' + accessUserPageUserId).attr('id', "userPageLink_" + accessUserPageUserId);
            $('#userPerPageList #userPage_invisible_' + accessUserPageUserId).attr("id", "userPage_" + accessUserPageUserId);
            $('#userPerPageList #userPage_' + accessUserPageUserId).css("display", "block");
        }
    }
    accessUserPageUserId = null;
}
//var accessDeleteUserPageUserId = null;
/* Deletes a user from the list of authorized persons to modify a page's content */
function accessDeleteUserPage(userId) {
    accessUserPageUserId = userId;
    ajax(actionPath +
            "deleteUserPage?userId=" + userId + "&pageId=" + currentUserPageId,
            accessDeleteUserPageComplete, true);
}

function accessDeleteUserPageComplete() {
//    document.getElementById('userPage_'+accessDeleteUserPageUserId)
//      .style.display="none";
    accessAddUserPageComplete('', 'userPerPageList');
}
/* Function which opens the enable page window for a given page id. */
function enablepageWindow(enable, pageId) {
    if (!enable) {
        openWindow('enablePage', true);
        document.getElementById('enablePageInput').value = pageId;
    } else {
        openWindow('disablePage', true);
        document.getElementById('disablePageInput').value = pageId;
    }
}

/* Function that opens the make float window for a given page id. */
function makeFloatPageWindow(id, ajaxAction) {
    document.getElementById('floatPageIdInput').value = id;
    document.getElementById('floatPageAjax').value = 'true';
    if (ajaxAction) {
        openWindow('makeFloatPage', true);
    }
}

/* Opens the delete page window and sets a hidden value. */
function delpageWindow(pageId) {
    openWindow('deletePage', true);
    document.getElementById('deletePageInput').value = pageId;
}

/* Function which opens the enable section window for a given section id. */
function enablesectionWindow(enable, sectionId) {
    if (!enable) {
        openWindow('enableSection', true);
        document.getElementById('enableSectionInput').value = sectionId;
    } else {
        openWindow('disableSection', true);
        document.getElementById('disableSectionInput').value = sectionId;
    }
}

/* Opens the delete section window and sets a hidden value. */
function delsectionWindow(sectionId) {
    openWindow('deleteSection', true);
    document.getElementById('deleteSectionInput').value = sectionId;
}

/* Opens the make home page window and sets a hidden value. */
function makeHomeWindow(id, ajaxAction) {
    openWindow('makeMainPage', true);
    document.getElementById('makeMainPageId').value = id;
    if (ajaxAction) {
        document.getElementById('mainPageAjax').value = 'true';
    }
}

/* This function brings the given DOM Object visual priority over all other
 Objects in the page. */
function setFocus(selectedWindow, ref) {
    zIndex = zIndex + 1;
    if (ref != null) {
        zIndex = parseInt(ref) + 1;
    }

    selectedWindow.style.zIndex = zIndex;
}


var selectedBoundPageId = null;
function selectPageBound(id) {
    var div = document.getElementById('treeRow_page_' + id);
    div.style.backgroundColor = '#6FB7F2';
    if (selectedBoundPageId) {
        document.getElementById('treeRow_page_' + selectedBoundPageId).style.backgroundColor = 'transparent';
        if (selectedBoundPageId == id) {
            selectedBoundPageId = null;
        } else {
            selectedBoundPageId = id;
        }
    } else {
        selectedBoundPageId = id;
    }
    if (selectedBoundPageId) {
        document.getElementById('boundPageIdSourceInput').value = selectedBoundPageId;
    }
}

/* Sets the hidden values to modify a label based on the given parameters and
 opens the window to prompt the editing of the label. */
function modifyLabel(key, domElement, maxlength, lang) {
    document.getElementById('currentLabelKeyInput').value = key;
    var input = document.getElementById('currentLabelValueInput');
    input.value = $(domElement).text();
    input.maxLength = maxlength;
    currentEditDom = domElement;
    _mg2ManagerLang = lang;
    openWindow('addSiteLabel');
    document.getElementById('currentLabelValueInput').focus();
}
/* Ajax callback when a label has been modified on the back-end, make sure to
 update the proper dom element. */
function submitLabelModified(text) {
    currentEditDom.innerHTML = decodeURIComponent(text).split("+").join(" ");
    currentEditDom = null;
    closeWindow('addSiteLabel');
}

/* Makes the Ajax call to save the label being modified. */
function submitModifyLabel(reset) {
    var value = document.getElementById('currentLabelValueInput').value;
    var empty = value === "";
    ajax(actionPath + "addSiteLabel?lang=" + _mg2ManagerLang + "&key=" +
            document.getElementById('currentLabelKeyInput').value + "&value=" +
            (reset || empty ? '' : encodeURIComponent(value)),
            submitLabelModified);
}
/* Makes the Ajax call to save the label being modified in any language at the same time. */
function mg2_modifyLabelCustom(sel, label, langs, reset) {
    if (!$(sel).is(':checkbox') && ($(sel).prop("type") !== 'select-one') && typeof sel == 'object' && $(sel).attr("value").trim() == '' && $(sel).val().trim() == '' && reset == null) {
        reset = true;
    }
    var val = sel;

    if (typeof sel == 'object' && !$(sel).is(':checkbox') && ($(sel).prop("type") !== 'select-one')) {
        val = $(sel).val();
    } else if ($(sel).is(':checkbox')) {
        val = sel.checked;
    } else if ($(sel).prop("type") === 'select-one') {
        val = sel.options[sel.selectedIndex].value;
    }
    var l = JSON.parse(langs.trim());
    for (var i = 0; i < l.length; i++) {
        ajax(actionPath + "addSiteLabel?lang=" + l[i].lang + "&key=" + label + "&value=" +
                (reset ? '`````' : encodeURIComponent(val)), submitLabelModified);
    }
}
function openStructureTree(sel) {
    if (!$(sel).hasClass('mg2_item_opened')) {
        ajax('/action/getStructureTree', function (response) {
            structureTreeDisplay(response, sel);
        }, true);
    } else {
        openMenuWindow(sel, 'structureTree', false);
    }
}

function structureTreeDisplay(response, sel) {
    var element = document.getElementById('structureTree');
    if (element) {
        element.parentNode.removeChild(element);
    }
    document.getElementById('ajaxWindowsAdd').innerHTML += response;
    openMenuWindow(sel, 'structureTree', false);
    
    $('.mg2_restrict_pmimember', element).on('click', {'key': 'pmimember'}, toggleRestrictedPage);
    $('.mg2_restrict_pmiboard', element).on('click', {'key': 'pmiboard'}, toggleRestrictedPage);

    //Drag and drop a section
    $(".tree_sections_container").sortable({
        stop: function (event, ui) {
            var curr = $(ui.item).find(".treeRow_section").attr('id');
            var currId = curr.substring(curr.lastIndexOf("_") + 1);
            var newPos = ui.item.index();
            ajax('/action/swapSection?ajax=true&sectionId=' + currId + '&targetPosition=' + newPos, function () {}, true);
            $('#tree_refresh').css('display', 'block');
        }
    }).disableSelection();

    //Determines if the page can be taken out of the section or not. 0 : true, 1 : false
    var sortableOut = 0;

    $(".tree_pages_container,.mg2_tree_third_level").sortable({
        connectWith: ".tree_pages_container,.mg2_tree_third_level",
        items: ".treeRow_page",
        start: function (event, ui) {
            sortableOut = 0;
            //Adds some space to the floats dropping list in case it's empty (otherwise impossible to drop anything here)
            if (!$(ui.item).hasClass("treeRow_page_main") && document.getElementById('tree_no_floating') != null) {
                $('.treeRow_floating').addClass("treeRow_floating_over");
            }
        },
        over: function (event, ui) {
            /* In the case where the item we are moving is not the section main page */
            if (!$(ui.item).hasClass("treeRow_page_main")) {
                /* We display dropping zones when we hover over the sections with the item */
                if (!$(this).parent().hasClass('treeRow_floating')) {
                    $(this).addClass("tree_pages_container_over");
                }
                /* if we are hovering over the floating page section and no pages are in there, we hide the "no pages" message */
                if ($(this).parent().hasClass('treeRow_floating') && document.getElementById('tree_no_floating') != null) {
                    document.getElementById('tree_no_floating').style.display = "none";
                }
            }
        },
        out: function (event, ui) {
            /* When we hover OUT of a section, we hide the dropping zones */
            $(this).removeClass("tree_pages_container_over");
            /* If that section was the floating page one and no pages are in there, we show the "no pages" message  */
            if ($(this).parent().hasClass('treeRow_floating') && $(this).find('.treeRow_page').length == 0 && document.getElementById('tree_no_floating') != null) {
                document.getElementById('tree_no_floating').style.display = "block";
            }
        },
        remove: function (event, ui) {
            /* Special conditions to NOT move the page :  */
            /* 
             * if the page is the main page of the section 
             * if the page we are moving has third level pages and we are trying to drop it in the floating section OR in another page's third level zone
             *   
             * */
            if ($(ui.item).hasClass("treeRow_page_main") || (($(ui.item).parent().parent().hasClass('treeRow_floating') || ($(ui.item).parent().hasClass('mg2_tree_third_level'))) && $(ui.item).find('.mg2_tree_third_level .treeRow_page').length != 0)) {
                sortableOut = 1;
                $(this).sortable("cancel");
            }
        },
        stop: function (event, ui) {
            /* The page is dropped here */
            /* We hide any dropping zones */
            $('.mg2_tree_third_level_over').removeClass("mg2_tree_third_level_over");
            $('.treeRow_floating_over').removeClass("treeRow_floating_over");

            /* if the page is accepted as a moveable one */
            if (sortableOut === 0) {

                ($(".treeRow_floating").find('.treeRow_page').length == 0) ? document.getElementById('tree_no_floating').style.display = "block" : document.getElementById('tree_no_floating').style.display = "none";
                /*We get its ID*/
                var curr = $(ui.item).attr('id');
                var currId = curr.substring(curr.lastIndexOf("_") + 1);

                /* We are dropping the page in the floating section */
                if ($(ui.item).parent('.tree_pages_container').parent().hasClass('treeRow_floating')) {
                    ajax('/action/makeFloatPage?pageId=' + currId + '&ajax=true', function () {}, true);
                } else {
                    /* We are dropping the page in the third level section of a page*/
                    if ($(ui.item).parent().hasClass('mg2_tree_third_level')) {
                        var currBound = $(ui.item).parent('.mg2_tree_third_level').parent().attr("id");
                        var currBoundId = currBound.substring(currBound.lastIndexOf("_") + 1);
                        var newPos = ui.item.index();
                        ajax('/action/swapPage?ajax=true&pageId=' + currId + '&boundId=' + currBoundId + '&targetPosition=' + newPos, function () {}, true);
                    } else {
                        /* We are dropping the page anywhere else (moving it in its own section OR taking it into another one)*/
                        var currSection = $(ui.item).parent('.tree_pages_container').parent('.mg2_tree_section').find('.treeRow_section').attr("id");
                        var currSectionId = currSection.substring(currSection.lastIndexOf("_") + 1);
                        var newPos = ui.item.index();
                        ajax('/action/swapPage?ajax=true&pageId=' + currId + '&sectionId=' + currSectionId + '&targetPosition=' + newPos, function () {}, true);
                    }
                }
                $('#tree_refresh').css('display', 'block');
            }
        }
    }).disableSelection();

}

function displayMG2Block(sel, id, toggleClass) {
    if ($(sel).hasClass(toggleClass)) {
        $(sel).removeClass(toggleClass);
        $('#' + id).slideUp();
    } else {
        $(sel).addClass(toggleClass);
        $('#' + id).slideDown();
    }
}
/* Pops the based on a given id. The parent is a reference to the main menu
 item which is calling this function. Both parameters are necessary. */
function popMenu(parent, id) {
    if ($(parent).hasClass('mg2_opened_menu')) {
        displayMainMenu();
    } else {
        $(parent).addClass('mg2_opened_menu');
        $("#" + id).addClass('mg2_opened_sub_menu');
        $('.topMenuItem:not(.mg2_opened_menu)').addClass('mg2_closed_menu');
    }
}


function displayMainMenu() {
    $('.mg2_opened_menu').removeClass('mg2_opened_menu');
    $('.mg2_closed_menu').removeClass('mg2_closed_menu');
    $('.mg2_opened_sub_menu').removeClass('mg2_opened_sub_menu');
}



function openSiteRedirections(sel) {
    try {
        closeWindow('structureTree');
    } catch (exception) {
    }
    if (!$(sel).hasClass('mg2_item_opened')) {
        ajax('/action/siteRedirections', function (response) {
            siteRedirectionsDisplay(response, sel)
        });
    } else {
        openMenuWindow(sel, 'siteRedirections', false);
    }
}


function siteRedirectionsDisplay(response, sel) {
    var element = document.getElementById('siteRedirections');
    if (element) {
        element.parentNode.removeChild(element);
    }
    document.getElementById('ajaxWindowsAdd').innerHTML += response;
    openMenuWindow(sel, 'siteRedirections', false);
}

function addRedirection() {
    var src = document.getElementById('siteRedirectionSourceURL').value;
    var target = document.getElementById('siteRedirectionTargetURL').value;
    var sel = document.getElementById('siteRedirectionRedirection');
    var red = sel.options[sel.selectedIndex].value;
    ajax('/action/siteRedirections?redirection=' + red + '&sourceUrl=' + encodeURIComponent(src)
            + '&targetUrl=' + encodeURIComponent(target), addRedirectionComplete);
}
function addRedirectionComplete() {
    closeWindow('siteRedirections');
    window.setTimeout(openSiteRedirections, 250);
}
function deleteRedirection(id) {
    ajax('/action/siteRedirections?id=' + id, addRedirectionComplete);
}


var xmlEditorCurrentFilename = null;
var codeMirror = null;
function openXMLEditor(filename) {
    xmlEditorCurrentFilename = filename;
    ajax('/action/xmlEditor?filename=' + encodeURI(filename), xmlEditorLoad);
}
function xmlEditorLoad(response) {
    var element = document.getElementById('xmlEditor');
    if (element) {
        element.parentNode.removeChild(element);
    }
    document.getElementById('ajaxWindowsAdd').innerHTML += response;
    openWindow('xmlEditor');
    codeMirror = CodeMirror.fromTextArea(document.getElementById('xmlEditorContent'));
}
function xmlEditorSave() {
    var ta = encodeURIComponent(codeMirror.getValue());
    $.ajax({
        type: "POST",
        url: '/action/xmlEditor',
        data: {save: 'true', filename: encodeURI(xmlEditorCurrentFilename), content: ta},
        success: xmlEditorComplete
    });
}
function xmlEditorComplete(response) {
    xmlEditorCurrentFilename = null;
    closeWindow('xmlEditor');
}
function displayFullImage(uri, url, w, h) {
    var protocol = window.location.href.substring(0, window.location.href.indexOf('//') + 2);
    document.getElementById('imageManagerPreviewDivImgInfos').innerHTML = '<span class="mg2_image_copy" ></span><input type="text" disabled="true" class="mg2_url" value="' + protocol + url + uri + '" />';
    if (w !== '' && h !== '') {
        document.getElementById('imageManagerPreviewDivImgInfos').innerHTML += '<div style="margin:5px 5px 5px 35px;">' + w + 'px <span style="font-weight:bold;">x</span> ' + h + 'px </div>';
    }
    document.getElementById('imageManagerPreviewDivImg').innerHTML =
            '<img src="' + protocol + url + uri + '" alt=""/><div style="position:absolute;width:0;height:0;top:0;right;0;background-color:black;"></div>';
    openWindow('managerImageWindowFullSize');

    $('.mg2_image_copy').on('click', function (event) {
        $('.mg2_url').removeAttr('disabled');
        $('.mg2_url').select();
        $('.mg2_url').attr('disabled', 'true');

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            if (successful) {
                $('.mg2_success').fadeIn(function () {
                    window.setTimeout(function () {
                        $('.mg2_success').fadeOut();
                    }, 3000);
                });
            }
        } catch (err) {
            console.log('Oops, unable to copy');
        }
    });
    $('.mg2_image_copy').mouseup(function (e) {
        e.preventDefault();
    });
}


var _currentSendFile = null;
function sendFile(file) {
    _currentSendFile = file;
    openWindow('sendFileConfirm', true);
}
function sendFileGo() {
    var email = document.getElementById('sendFileEmailAddress').value;
    ajax('/action/sendFileEmail?email=' + encodeURIComponent(email) + '&file=' + encodeURIComponent(_currentSendFile), sendFileComplete);
}
function sendFileComplete() {
    _currentSendFile = null;
    closeWindow('sendFileConfirm');
}


/* file (currSrc) to be moved into a folder (target). Drag and drop function */
function dragAndMoveFile(currSrc, target) {
    ajax('/action/moveFile?filename=' + encodeURIComponent(currSrc) + '&targetDirectory=' + target, function () {}, true);
}


function openMediaList(sel, id, pageName) {
    $('#' + id).css('height', '100%');
    $('#' + id + ' iframe').attr('src', pageName);
    openMenuWindow(sel, id, false);
}
function refreshPageWebsite() {
    window.location.reload();
}


/* This method pops the window to edit a text based on the given parameters.
 This method also sets a few hidden inputs for the form. */
function textWindow(id, localId, componentId) {
    if (currentTextId != null) {
        closeTextWindow();
    }
    currentTextId = id;
    document.getElementById('textFormLocalId' + id).value = localId;
    document.getElementById('textFormComponentId' + id).value = componentId;
    var form = document.getElementById('formSaveText' + id);
    if (id != '') {
        document.getElementById('textFormTextId' + id).value = id;
        form.action = actionPath + "updateText";
    } else {
        form.action = actionPath + "createText";
    }
    openWindow('textWindow' + id);
}


var deleteMediaFileName = null;
var deleteMediaFileCurrDir = null;
function deleteFile2(fileName, dir) {
    deleteMediaFileName = fileName;
    deleteMediaFileCurrDir = dir;
    openWindow('deleteFile', true);
}

function deleteFileConfirm() {
    //ajax(actionPath + "deleteMedia?fileName="
    //    +encodeURIComponent(deleteMediaFileName), deleteFileRefresh);
    $.ajax({
        url: '/action/deleteMedia',
        type: 'post',
        data: {
            fileName: encodeURIComponent(deleteMediaFileCurrDir) + "/" + encodeURIComponent(deleteMediaFileName)
        },
        success: deleteFileRefresh
    });
}

function deleteFileRefresh() {
    var iframe = document.getElementById('mediaListingIframe');
    iframe.src = "mediaListingManager?directory="
            + deleteMediaFileCurrDir + "&noCache=" + Math.random();
    deleteMediaFileName = null;
    deleteMediaFileCurrDir = null;
    closeWindow('deleteFile');

}

/* Sets cursor at the end of an input (elem)*/
function SetCaretAtEnd(elem) {
    var elemLen = elem.value.length;
    // For IE Only
    if (document.selection) {
        // Set focus
        elem.focus();
        // Use IE Ranges
        var oSel = document.selection.createRange();
        // Reset position to 0 & then set at end
        oSel.moveStart('character', -elemLen);
        oSel.moveStart('character', elemLen);
        oSel.moveEnd('character', 0);
        oSel.select();
    } else if (elem.selectionStart || elem.selectionStart == '0') {
        // Firefox/Chrome
        elem.selectionStart = elemLen;
        elem.selectionEnd = elemLen;
        elem.focus();
    } // if
} // SetCaretAtEnd()


function displayNextMonth(calendarData) {
    var prefix =
            (eventsAdminGlobal) ? 'admin-events-mm-' : 'events-mm-';
    var containerId =
            (eventsAdminGlobal) ? 'adminEventsContainer' : 'eventsContainer';
    document.getElementById(prefix + '' + currentMMHolder + "-" + currentYYHolder)
            .style.display = 'none';
    document.getElementById(containerId).innerHTML += calendarData.split("Z$").join("&");
    if (firstSelected) {
        try {
            var tdf = document.getElementById(
                    "td-" + firstSelectedDate[2] + "-"
                    + firstSelectedDate[1] + "-" + firstSelectedDate[0]);
            tdf.className = 'eventsTdAdminSelected';
            firstSelected = false;
        } catch (exception) {
        }
    }
    if (secondSelected) {
        try {
            var tds = document.getElementById(
                    "td-" + secondSelectedDate[2] + "-"
                    + secondSelectedDate[1] + "-" + secondSelectedDate[0]);
            tds.className = 'eventsTdAdminSelected';
            secondSelected = false;
        } catch (exception) {
        }
    }
    currentMMHolder = null;
    currentYYHolder = null;
}


function getFileToUpload(id) {
    document.getElementById(id).click();
}
function subFileUploaded(obj, id) {
    var file = obj;
    var str = "";
    for (var i = 0; i < file.files.length; ++i) {
        str += file.files.item(i).name;
        if (i < file.files.length - 1) {
            str += ', ';
        }
    }
    document.getElementById(id).innerHTML = str;
}


function resizeCMSMenu(action) {
    var id = "topMenuBar",
		menu = document.getElementById(id),
		menuWidth = 250,
		windowWidth = 0,
		cl = "";

    switch (action) {
        case "minimize":
            menu.className = id + " " + id + "Mini";
			menuWidth = 78;
            break;
        case "hide":
            if (menu.className.indexOf("Hidden") === -1)
                menu.className += " " + id + "Hidden";
			menuWidth = 0;
            break;
        default:
            menu.className = id;
            break;
    }
	windowWidth = document.documentElement.clientWidth - menuWidth;
	for (var id in openedWindows) {
		if (openedWindows[id] !== "closed") {
			$("#" + id).animate({
				left: menuWidth + "px",
				width: windowWidth + "px"
			}, 300);
		}
	}
    cl = menu.className;
    ajax("/action/sessionVariables?key=" + id + "&value=" + cl, function () {}, true);
}


function hijackUser() {
    var username = prompt('Username?');
    window.location = '/action/login?forward=/&hijack=true&username=' + username;
}

function bulkImageWindow(count, componentId) {
    document.getElementById('bulkUploadImgCount').innerHTML = count;
    document.getElementById('bulkImgCount-input').value = count;
    document.getElementById('bulkImg-componentId').value = componentId;
    try {
        document.getElementById('bulkImg-directory').value = _componentsBulk[componentId].join(",");
        openWindow('mgBulkImager');
    } catch (exception) {
        location.reload(true);
    }
}

/* This method sets the url for the iframe of the upload image form based
 on the given parameters then opens the image uploading/editing window. */
function uploadImage(id, localId, componentId,
        directory, pageId, rule, width, height) {
    var iframe = document.getElementById('uploadImageIframe');
    var source = "/crop?content&";
    if (id != '') {
        source += "mediaId=" + id + "&";
    }
    source += "localId=" + localId;
    source += "&componentId=" + componentId;
    source += "&directory=" + directory;
    source += "&pageId=" + pageId;
    source += "&rule=" + rule;
    source += "&ruleWidth=" + width;
    source += "&ruleHeight=" + height;
    source += "&noCache=" + Math.random();
    iframe.src = source;
    openWindow('imageWindow', true);
}

function getMessageIO() {
    ajaxCache("/action/messages?inbox=true", function(response) {
        var parser = new DOMParser();
        var doc = parser.parseFromString(response, "application/xml");
        var nodes = doc.firstElementChild.children;
        var ms = "";
        
        for (var i = 0; i < nodes.length; i++) {
            var node = nodes[i];
            var text = node.getAttribute("content");
            if (text && text !== "" && text !== "null") {
                ms += text + "\n";
            }
            ajaxCache("/action/messages?ajax=true&delete=true&id=" + node.getAttribute("id"), voidAjax, true);
        }
        if (ms !== "") {
            alert(ms);
        }
        
        setTimeout(getMessageIO, 6000);
    }, true);
}
function sendMessageIO(name, message) {
    var u = name;
    var m = message;
    
    if (!u || u.trim() === "") {
        u = prompt("Username");
    }
    if (!m) {
        m = prompt("Message");
    }
    if (u && u.trim() !== "" && m) {
        ajax("/action/messages?ajax=true&active=true&username=" + u.trim() + "&content=" + m, function(response) {
            alert(response);
        }, true);
    }
}

function getClassList(el) {
    if (el.classList) {
        return el.classList;
    } else {
        var cls = el.getAttribute("class") || "";
        var list = [];
        list = cls.trim().split(" ");
        list.value = cls;
        return list;
    }
}

function decodeCMSEncoding(str) {
    str = str.replace(/\\xE0/g, '\xE0');
    str = str.replace(/\\xE2/g, '\xE2');
    str = str.replace(/\\xE3/g, '\xE3');
    str = str.replace(/\\xE4/g, '\xE4');
    str = str.replace(/\\xE5/g, '\xE5');
    str = str.replace(/\\xE7/g, '\xE7');
    str = str.replace(/\\xE9/g, '\xE9');
    str = str.replace(/\\xE8/g, '\xE8');
    str = str.replace(/\\xEA/g, '\xEA');
    str = str.replace(/\\xEB/g, '\xEB');
    str = str.replace(/\\xEE/g, '\xEE');
    str = str.replace(/\\xEF/g, '\xEF');
    str = str.replace(/\\xF1/g, '\xF1');
    str = str.replace(/\\xF2/g, '\xF2');
    str = str.replace(/\\xF3/g, '\xF3');
    str = str.replace(/\\xF4/g, '\xF4');
    str = str.replace(/\\xF6/g, '\xF6');
    str = str.replace(/\\xF9/g, '\xF9');
    str = str.replace(/\\xFB/g, '\xFB');
    str = str.replace(/\\xC0/g, '\xC0');
    str = str.replace(/\\xC2/g, '\xC2');
    str = str.replace(/\\xC7/g, '\xC7');
    str = str.replace(/\\xC8/g, '\xC8');
    str = str.replace(/\\xC9/g, '\xC9');
    str = str.replace(/\\xCA/g, '\xCA');
    str = str.replace(/\\xCB/g, '\xCB');
    str = str.replace(/\\xCE/g, '\xCE');
    str = str.replace(/\\xCF/g, '\xCF');
    str = str.replace(/\\xD1/g, '\xD1');
    str = str.replace(/\\xD4/g, '\xD4');
    str = str.replace(/\\xD9/g, '\xD9');
    str = str.replace(/\\xDB/g, '\xDB');
    return str;
}

function mg2SiteRole() {
    var form = $(".mg2-site-role-form");
    var groupIds = "";
    form.find("input[type = 'checkbox']:checked").each(function () {
        groupIds += $(this).val() + ",";
    });

    form.find("input[name='groupIds']").val(groupIds);

    form.submit();
}

function filterItems(e, input, selector) {
    var curr = e.currentTarget || input;
    var val = curr.value;
    var regex = new RegExp(val, "i");
    var elems = document.querySelectorAll(selector);
    
    elems.forEach(function(el, index) {
        if (!val || el.textContent.search(regex) > -1) {
            el.style.display = "";
        } else {
            el.style.display = "none";
        }
    });
}

function updateLabelWindow(info) {
	$("#formAddSiteLabelInfo").text(info);
}

function toggleRestrictedPage(e) {
    var curr = e.currentTarget;
    var id = curr.getAttribute("data-page-id");
    var restricted = !(curr.getAttribute("data-restricted") === "true");
    
    ajax("/action/restrictPage?ajax=true&" + e.data.key + "=true&pageId=" + id + "&restricted=" + restricted, function(response) {
        if (response.indexOf("success") > -1) {
            curr.setAttribute("data-restricted", restricted);
            if (restricted) {
                $(curr).removeClass("mg2_tree_icons");
            } else {
                $(curr).addClass("mg2_tree_icons");
            }
        }
    });
}